(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name rcrFrontend.service:Alerts
   *
   * @description
   *
   */
  angular
    .module('rcrFrontend')
    .service('Alerts', Alerts);

  function Alerts($window, $timeout) {
    var self = this;

    self.get = function () {
      return 'Alerts';
    };
	self.alerts = [];
	
	var alertid = 0;
	
	//create an alert and a unique identifier for that instance.
	//alert type should be "ok" (green), "info" (orange) or "error" (red) if left blank will default to "info"
	self.addAlert = function(alertType, message) {
		var obj = {type: alertType, msg: message, id: alertid}
		self.alerts.push(obj);
		$window.scrollTo(0,0);
		$timeout(function(){
			self.timeClose(obj.id)
		}, 5000);
		alertid++;
		
	};
	
	//close triggered by click
	self.closeAlert = function(index) {
		self.alerts.splice(index, 1);
	};
	
	//close caused by time
	self.timeClose = function(id){
		_.forEach(self.alerts, function(alert, key){
			if(alert.id == id){
				self.alerts.splice(key, 1);
				return false;
			}
		});
	}
  }
}());
